<template>
  <el-select
    ref="mySelect"
    v-bind="$attrs"
    v-model="optionValue"
    :multiple="false"
    :disabled="disabled"
  >
    <el-option :value="optionValue" :label="optionValue" class="options">
      <el-tree
        id="tree-option"
        ref="selectTree"
        default-expand-all
        :data="options"
        :props="defaultProps"
        @node-click="handleNodeClick"
      />
    </el-option>
  </el-select>
</template>

<script>
  import { defineComponent, ref, watch, onMounted, reactive, toRefs } from 'vue'

  export default defineComponent({
    name: 'mySelect',
    props: {
      modelValue: { type: String, default: '' },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: () => [
          {
            label: '选项1',
            value: '1',
            children: [{ label: '选项1-1', value: '1-1' }],
          },
          { label: '选项2', value: '2' },
        ],
      },
    },
    emits: ['nodeClick', 'update:modelValue'],
    setup(props, context) {
      function getLable(arr, value) {
        let res = ''
        function find(arr, value) {
          for (let i = 0; i < arr.length; i++) {
            console.log(arr[i], value)
            if (arr[i].deptId === value || arr[i].id === value) {
              res = arr[i].deptName
            }
            if (arr[i].children && arr[i].children.length) {
              find(arr[i].children, value)
            }
          }
        }
        find(arr, value)
        console.log(res)
        return res
      }
      watch(
        () => {
          return props.modelValue
        },
        () => {
          optionValue.value = getLable(props.options, props.modelValue)
        }
      )
      onMounted(() => {
        optionValue.value = getLable(props.options, props.modelValue)
      })
      const mySelect = ref()

      const optionValue = ref('')
      function handleNodeClick(node) {
        optionValue.value = node.deptName
        mySelect.value.blur()
        context.emit('nodeClick', node)
        context.emit('update:modelValue', node.deptId)
      }

      const state = reactive({
        defaultProps: {
          children: 'children',
          label: 'deptName',
        },
      })
      return {
        ...toRefs(state),
        mySelect,
        handleNodeClick,
        optionValue,
      }
    },
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
  }
  .el-select-dropdown__item.selected {
    font-weight: normal;
  }
  ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
  }
  .el-tree-node__label {
    font-weight: normal;
  }
  .el-tree >>> .is-current .el-tree-node__label {
    font-weight: 700;
    color: #409eff;
  }
  .el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    font-weight: normal;
    color: #606266;
  }
  .selectInput {
    box-sizing: border-box;
    padding: 0 5px;
  }
</style>
